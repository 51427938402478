import { useUlService } from '../../../Services/UlSubjectService';
import { useFlService } from '../../../Services/FlSubjectService';

export default function useCustomerUpdate() {
  const { updateUlSubject, createUlSubject } = useUlService();
  const { updateFlSubject, createFlSubject } = useFlService();

  function getDigits(str) {
    let result = str.match(/\d/g);
    result = result.join("");
    return result
  }

  async function updateCustomer(customer, customerType) {
    let requestObject = customer;
    requestObject.Inn = getDigits(customer.Inn)
    requestObject.Phone = getDigits(customer.Phone);
    requestObject.CountryCode = customer.Country?.code
    if (customerType === "fl") {
      console.log(customer.DocType)
      if (customer.DocType?.code === "008001001000" || customer.DocType?.code === "008001011000") {
        requestObject.CountryCode = "848000000643";
      }
      requestObject.GenderCode = customer.Gender.code
      requestObject.DocTypeCode = customer.DocType.code
      requestObject.GenderCode = customer.Gender.code
      requestObject.Snils = getDigits(customer.Snils)
    }

    if (customerType === "ul") {
      requestObject.UlTypeId = 1
      requestObject.Kpp = getDigits(customer.Kpp)
      requestObject.Ogrn = getDigits(customer.Ogrn)
    }
    if (customer.Id != null) {
      const fetchCustomersUpdate = customerType === "fl" ? updateFlSubject : updateUlSubject;
      const response = await fetchCustomersUpdate(requestObject);
    }
    else {
      const fetchCustomersCreate = customerType === "fl" ? createFlSubject : createUlSubject;
      const response = await fetchCustomersCreate(requestObject)
    }
  }

  return { updateCustomer }
}
