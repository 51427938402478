import Modal from "../../../Components/Modal/Modal";
import Select from "../../../Components/Select/Select";
import SmallCard from "../../../Components/SmallCard/SmallCard";
import avatar from "../../../images/Avatar_2.svg"
import useRequestContext from '../useRequestContext';
import { useEffect, useState } from 'react';
import { useRequestService } from '../../../Services/Request/RequestService';
import styles from '../UlSubject/add.ul.module.css';
import Input from '../../../Components/Input/Input';
export default function AddUlRepresentingFlModal({ isModal, setModal, flSubject, ulSubjectId, setUlSubjectId, ulSubjectList, participant, ulSubjects }) {
  const { request, addUlRepresentingFl, getRequest } = useRequestContext()
  const {updateUlRepresentingFl} = useRequestService()
  const [ulAgent, setUlAgent] = useState('')
  const [subject, setSubject] = useState({
    RequestId: null,
    TypeId: 0,
    FractionNumerator: null,
    FractionDenominator: null,
    UlAgentId: null,
    FlSubjectId: null,
    Privileged: true,
  })

  const onUlRepresentingSave = () => {
    if (!participant?.UlAgentFlSubject) {
      addUlRepresentingFl(subject).then(() => getRequest(request.Id))
    } else {
      updateUlRepresentingFl({ ...subject, Id: participant?.Id }).then(() => getRequest(request.Id))
    }
  }

  const getUlAgentName = (ulId) => {
    setUlAgent((ulSubjects?.find(subject => subject.Id === ulId)?.Agents?.find((agent) => agent?.IsMain))?.FlSubjectFIO)
  }

  useEffect(() => {
    if (subject?.UlAgentId) {
      getUlAgentName(subject?.UlAgentId)
    }
  }, [subject?.UlAgentId])

  useEffect(() => {
    if (isModal) {
      setSubject({
        RequestId: request?.Id,
        UlAgentId: flSubject?.UlSubjectId,
        FlSubjectId: flSubject?.FlSubjectId ? flSubject?.FlSubjectId : flSubject?.Id,
        FractionNumerator: participant?.Numerator ?? null,
        FractionDenominator: participant?.Denominator ?? null,
        Privileged: true,
      });
    }
  }, [isModal, flSubject, participant, request]);

  const handleModalClose = () => {
    setModal(false);

    setSubject({
      RequestId: null,
      TypeId: 0,
      FractionNumerator: null,
      FractionDenominator: null,
      UlAgentId: null,
      FlSubjectId: null,
      Privileged: true,
    });
  };

  return (
    <Modal
      onSave={onUlRepresentingSave}
      isOpen={isModal}
      setOpen={handleModalClose}
      title="Добавление ЮЛ как представитель ФЛ"
    >
      <label>Физ лицо</label>
      <SmallCard
        title={`${flSubject?.Lastname} ${flSubject?.Firstname} ${flSubject?.Middlename}`}
        subtitle={`СНИЛС: ${flSubject?.Snils}`}
        avatar={avatar}
      />
      {request?.RightType?.code === '001002000000'
        ?
        <div>
          <label>Доля</label>
          <div className={styles.wrapper}>
            <Input value={subject?.FractionNumerator ?? ''} onChange={(value) => setSubject({...subject, FractionNumerator: value ? Number(value) : null})}/>
            <span>/</span>
            <Input value={subject?.FractionDenominator ?? ''} onChange={(value) => setSubject({...subject, FractionDenominator: value ? Number(value) : null})}/>
          </div>
        </div>
        :
        null
      }
      <Select
        value={subject?.UlAgentId}
        onChange={(value) => {
          setUlSubjectId(value)
          setSubject({...subject, UlAgentId: value })
        }}
        label="ЮЛ представитель"
      >
        {ulSubjectList.map((x) =>
          <option value={x.Id}>{x.Name}</option>
        )}
      </Select>
      {ulAgent &&
        <Input value={ulAgent} disabled label='Подписант'/>
      }
    </Modal>
  )
}