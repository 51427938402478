import styles from "./navlink.module.css";
import { Link } from 'react-router-dom';

export default function Navlink({ href, active, icon, text, badge }) {
    return (
        <Link
            to={href}
            className={`${styles.container} ${active ? styles.active : ''}`}
        >
            <img src={icon} className={styles.icon} />
            <span className={styles.text}>{text}</span>
            {badge && badge}
        </Link>
    )
}