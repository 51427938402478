import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { Tooltip, GlobalStyles } from '@mui/material';
import { HttpContext } from '../../../../HttpContext';
import getStatus from '../../../../Services/PostNotifications/PostNotificationStatuses';
import styles from './homes.button.module.css';
import color from '../../../../styles/color.module.css';

const HomesButton = ({ text, type = 'gray', onClick, apartment }) => {
  const [isSent, setIsSent] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const { state } = useContext(HttpContext);
  const { flSubjects } = state;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    if (apartment?.Owners && apartment?.Owners.length > 0) {
      const hasSent = apartment?.Owners?.some(item =>
        item.State === "ONLINE_DELIVERED" ||
        item.State === "DELIVERED_TO_PRINT" ||
        item.State === "HANDED_EMAIL" ||
        item.State === "HANDED_MAIL"
      );
      setIsSent(hasSent);
    } else {
      setIsSent(false);
    }
  }, [apartment?.Owners]);

  return (
    <>
      <GlobalStyles
        styles={{
          '.MuiTooltip-tooltip': {
            whiteSpace: 'normal !important',
            maxWidth: 'none !important',
            minWidth: '300px !important',
          },
        }}
      />
      <Tooltip
        arrow
        title={
          apartment && apartment?.ApartmentStatus !== 1 ?
            <div className={styles.wrapper}>
              {apartment?.Owners.map((item) => (
                <div className={styles.title} key={item.FlOwnerId}>
                  <label className={styles.title_text}>{`${flSubjects?.find((x) => x.Id === Number(item.FlOwnerId))?.Lastname} 
                      ${flSubjects?.find((x) => x.Id === Number(item.FlOwnerId))?.Firstname} 
                      ${flSubjects?.find((x) => x.Id === Number(item.FlOwnerId))?.Middlename}`}</label>
                  <label className={styles.title_text}>Статус: {getStatus(item?.State).text}</label>
                  {item?.SentDate &&
                    <label className={styles.title_text}>Дата отправки: {formatDate(item?.SentDate)}</label>
                  }
                </div>
              ))}
              {apartment?.ApartmentStatus === 10 &&
                <div className={styles.title}>
                  <label className={styles.title_text}>Статус: Права зарегистрированы</label>
                </div>
              }
            </div>
            : null
        }
        className={cn(styles.container, {
          [color[type]]: type,
          [styles.yellow]: apartment?.ApartmentStatus === 2,
          [styles.green]: apartment?.ApartmentStatus === 10
        })}
        onClick={onClick}
        onMouseEnter={() => setOpenTooltip(true)}
        onMouseLeave={() => setOpenTooltip(false)}
        open={openTooltip}
      >
        {isSent && <span className={styles.comments} />}
        {text}
      </Tooltip>
    </>
  );
};

export default HomesButton;
