import { useContext, useEffect, useRef, useState } from "react";
import { HttpContext } from "../HttpContext";
import { actions } from './reducer/actions';

export function useFlService(subjectId, type) {
  const { dispatch, httpClient } = useContext(HttpContext);
  const [flSubject, setFlSubject] = useState({});
  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (!isNaN(parseInt(subjectId)) && type === "fl") {
      getFlSubject(subjectId).then(data => setFlSubject(data))
    }
  }, [subjectId, type])

  const getFlSubjects = async () => {
    const result = await httpClient.get("/flsubject/all?page=1&pageSize=10000");
    dispatch({
      type: actions.flSubjects,
      payload: result.data.Data
    });
    return result.data.Data;
  }

  const getFlSubjectsPage = async (page, pageSize) => {
    return httpClient.get(`/flsubject/all?page=${page}&pageSize=${pageSize}`, {
      hideLoader: true
    }).then(promise => promise.data)
  }

  const getFlSubject = async (id) => {
    return httpClient.get(`/flsubject/${id}`).then(promise => promise.data)
  }

  const deleteFlSubject = async (id) => {
    return httpClient.delete(`/flsubject/${id}`)
  }

  const updateFlSubject = async (body) => {
    return httpClient.put("/flsubject", body).then(promise => promise.data)
  }

  const createFlSubject = async (body) => {
    return httpClient.post("/flsubject", body).then(promise => promise.data)
  }

  const searchFlSubjects = async (value, page) => {
    value = value.trim()

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const response = await httpClient.get(`/flsubject/all?page=${page ? page : 1}&pageSize=10&all=${value}`, {
        signal: abortController.signal
      });
      return response.data;
    } catch (error) {
    }
  }

  const searchFlSubjectsAll = async (value) => {
    value = value.trim()

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const response = await httpClient.get(`/flsubject/all?page=1&pageSize=1000&all=${value}`, {
        signal: abortController.signal
      });
      return response.data;
    } catch (error) {
      return null
    }
  }

  return { getFlSubjects, getFlSubject, updateFlSubject, deleteFlSubject, createFlSubject, getFlSubjectsPage, flSubject, setFlSubject, searchFlSubjects, searchFlSubjectsAll }
}