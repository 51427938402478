import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css'
import App from './Pages/Navbar/NavBar';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Register from './Pages/Register/Register';
import Login from './Pages/Login/Login';
import NavBar from './Pages/Navbar/NavBar';
import routes from "./routes.js"
import {  HttpContextProvider } from './HttpContext';
const router = createBrowserRouter(routes);
const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.location.hostname === "goskluch.pro") {
  document.title = "Госключ.Про"
  if (window.location.pathname != "/demo" && window.location.pathname != "/login") {
    window.location.href = "/demo"

  }
}
root.render(
  <React.StrictMode>
    <HttpContextProvider>
      <RouterProvider router={router} />
    </HttpContextProvider>
  </React.StrictMode>
);

