import { useEffect, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";

import Button from "../Components/Button/Button";

import titles from "./pagesTitles.json";
import plus from "../icons/plus.svg";
import search from "../icons/search.svg";
import styles from "./pages.layout.module.css";


export default function PagesLayout() {
    const location = useLocation();
    const [title, setTitle] = useState(titles.find(x => x.path === window.location.pathname))

    useEffect(() => {
        setTitle(titles.find(x => x.path === location.pathname))
    }, [location]);

    return (
      <div className={styles.container}>
        <div className={styles.header}>
            <div className={styles.titleBlock}>
                <h1 className={styles.title}>{title.title}</h1>
                <span className={styles.subtitle}>{title.subtitle}</span>
            </div>
            <div className={styles.buttons}>
                {location.pathname === '/pages/objects' &&
                  <Button icon={search} href="/object/search" text='Поиск объекта'/>
                }
                {title.actionUrl != null ? <Button icon={plus} href={title.actionUrl} text={title.actionText}/> : ""}
            </div>
        </div>
        <Outlet/>
    </div>)
}