import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import titles, { step4 } from "./requestTitles";
import styles from "./request.layout.module.css";
import { useRequestService } from "../../../Services/Request/RequestService";
import Badge from "../../../Components/Badge/Badge";

export default function RequestLayout() {
  const { id } = useParams();
  const { request, regTypes, rightTypes, setRequest, getRequests, saveDocument, documentTypes, requestStatuses, deleteDocument, status, deleteParticipant, objectTypes, addFlSubject, addFlSubjectWithAgent, generateStatement, createRequest, addUlAgent, addSignature, addUlRepresentingFl, sendStatement, addDocumentParticipant, deleteDocumentParticipant, getRequest, updateRequest, updateFlSubject, updateFlSubjectWithAgent, updateUlAgent, updateUlRepresentingFl } = useRequestService(id)
  const [title, setTitle] = useState(titles[0])
  const [isDisabled, setIsDisabled] = useState(false)
  useEffect(() => {

    setTitle(titles.find(x => window.location.pathname.includes(x.path)))

  }, [window.location.pathname])
  useEffect(() => {
    if (status.code != 1) {
      setIsDisabled(true)
    }
  }, [status])
  useEffect(() => {
    if (status.code == 5) {
      setTitle({ ...title, step: step4 })
    }
  }, [, window.location.pathname])
  return (
    <div className={styles.container}>
      {request?.Number != null &&
        <h1 className={styles.title}>
          {`Заявка #${request.Number}`}
          <Badge type={status.style} text={status.name}></Badge>
        </h1>
      }
      <div className={styles.subtitleBlock}>
        {title.subtitle != null &&
          <span className={styles.subtitle}>
            {title.subtitle}
          </span>
        }
        {title != null &&
          <span className={styles.subtitle2}>
            {title.option}
          </span>
        }
      </div>
      <img src={title.step} />
      <Outlet context={[request, regTypes, rightTypes, setRequest, getRequests, saveDocument, documentTypes, requestStatuses, deleteDocument, status, deleteParticipant, objectTypes, addFlSubject, addFlSubjectWithAgent, generateStatement, createRequest, addUlAgent, id, isDisabled, addSignature, addUlRepresentingFl, sendStatement, addDocumentParticipant, deleteDocumentParticipant, getRequest, updateRequest, updateFlSubject, updateFlSubjectWithAgent, updateUlAgent, updateUlRepresentingFl]} />

    </div >
  )
}