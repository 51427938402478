import React from "react";

import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";
import { useCountryService } from '../../../Services/CountryService';
import AddressInput from '../../../Components/Input/AddressInput';

import styles from "./customer.edit.module.css"

export default function FlSubjectEdit({ wrap, customer, setCustomer }) {
  const {countries} = useCountryService()

  return (
    <React.Fragment>
      <div className={styles.separated} style={{ flexWrap: wrap != null ? "wrap" : "nowrap" }}>
        <Input
          value={customer.Snils}
          onChange={(value) => setCustomer({ ...customer, Snils: value })}
          name="snils"
          label="СНИЛС"
          mask="000-000-000 00"
          placeholder="351-456-335 22"
        />
        {customer.DocType?.code !== "008001011000" &&
          <Input
            value={customer.Inn}
            onChange={(value) => setCustomer({ ...customer, Inn: value })}
            name="inn"
            label="ИНН"
            mask="000 000 000 000"
            placeholder="455 352 647 368"
          />
        }
      </div>
      <h3>Паспортные данные</h3>
      <div className={styles.separated} style={{ flexWrap: wrap != null ? "wrap" : "nowrap" }}>
        <Input
          value={customer.Lastname}
          onChange={(value) => setCustomer({ ...customer, Lastname: value })}
          name="surname"
          label="Фамилия"
          placeholder="Соловьёв"
        />
        <Input
          value={customer.Firstname}
          onChange={(value) => setCustomer({ ...customer, Firstname: value })}
          name="name"
          label="Имя"
          placeholder="Иван"
        />
        <Input
          value={customer.Middlename}
          onChange={(value) => setCustomer({ ...customer, Middlename: value })}
          name="middlename"
          label="Отчество"
          placeholder="Алексеевич"
        />
      </div>
      <div className={styles.separated} style={{ flexWrap: wrap != null ? "wrap" : "nowrap" }}>
        <Select
          value={customer.Gender?.code}
          onChange={(value) => setCustomer({
            ...customer,
            Gender: {
              ...customer.Gender, code: value
            }
          })}
          label="Пол"
          placeholder="Выберите из списка..."
        >
          <option value={1}>Мужской</option>
          <option value={2}>Женский</option>
        </Select>
        <Input
          onChange={(value) => setCustomer({
            ...customer,
            BirthDate: value
          })}
          value={customer.BirthDate}
          name="birthdate"
          mask={Date}
          placeholder="дд.мм.гггг"
          label="Дата рождения"
        />
      </div>
      <Input
        value={customer.BirthPlace}
        onChange={(value) => setCustomer({
          ...customer,
          BirthPlace: value
        })}
        name="birthplace"
        label="Место рождения"
        placeholder="г. Москва"
      />
      <Select
        value={customer.DocType?.code}
        onChange={(value) => setCustomer({
          ...customer,
          DocType: { code: value }
        })}
        label="Тип документа"
        placeholder="Выберите из списка..."
      >
        <option value="008001001000">Паспорт гражданина Российской Федерации</option>
        <option value="008001011000">Свидетельство о рождении</option>
        <option value="008001003000">Паспорт гражданина иностранного государства</option>
      </Select>
      {customer.DocType?.code === "008001003000" &&
        <Select
          value={customer.Country?.code}
          onChange={(value) => setCustomer({
            ...customer,
            Country: { code: value }
          })}
          label="Страна"
          placeholder="Выберите из списка..."
        >
          {countries?.map((x) =>
            <option value={x.code}>{x.name}</option>

          )}
        </Select>
      }

      <div className={styles.separated} style={{ flexWrap: wrap != null ? "wrap" : "nowrap" }}>
        <Input
          value={customer.DocSeries}
          onChange={(value) => setCustomer({
            ...customer,
            DocSeries: value.replace(/\s+/g, '')
          })}
          name="docseries"
          label="Серия документа"
          mask="00 00"
          placeholder="09 15"
        />
        <Input
          value={customer.DocNumber}
          onChange={(value) => setCustomer({
            ...customer,
            DocNumber: value
          })}
          name="docnumber"
          label="Номер документа"
          mask="000000"
          placeholder="336876"
        />
        <Input
          value={customer.DocDate}
          onChange={(value) => setCustomer({
            ...customer,
            DocDate: value
          })}
          name="issuedate"
          mask={Date}
          placeholder="дд.мм.гггг"
          label="Дата выдачи документа"
        />
        {customer.DocType?.code !== "008001011000" &&
          <Input
            value={customer.DocIssuerCode}
            onChange={(value) => setCustomer({
              ...customer,
              DocIssuerCode: value
            })}
            label="Код подразделения"
            mask="000-000"
            placeholder="910-000"
          />
        }
      </div>
      <Input
        value={customer.DocIssuerOrgan}
        label="Кем выдан"
        onChange={(value) => setCustomer({
          ...customer,
          DocIssuerOrgan: value
        })}
        placeholder="ГУ МВД по г. Москва"
      />
      <div className={styles.separated} style={{ flexWrap: wrap != null ? "wrap" : "nowrap" }}>
        <AddressInput
          value={customer.Address?.Note}
          setValue={(value) => {
            setCustomer({
              ...customer,
              Address: value
            });
          }}
          label="Адрес регистрации"
          placeholder="Планерная 10, кв 254"
          name="address"
        />
      </div>
      <h3>Контакты</h3>
      <div className={styles.separated} style={{ flexWrap: wrap != null ? "wrap" : "nowrap" }}>
        <Input
          value={customer.Phone}
          mask="+7 (000) 000-00-00"
          onChange={(value) => setCustomer({
            ...customer,
            Phone: value
          })}
          name="phone"
          type="phone"
          label="Телефон"
          placeholder="+7 (456) 894 22 11"
        />
        <Input
          value={customer.Email}
          onChange={(value) => setCustomer({
            ...customer,
            Email: value
          })}
          name="email"
          type="email"
          label="Электронная почта"
          placeholder="example@yandex.ru"
        />
      </div>
    </React.Fragment>
  )
}