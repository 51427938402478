import React, { useState } from 'react';
import styles from './object.search.module.css';
import CardHeader from '../../../Components/Card/CardHeader';
import Input from '../../../Components/Input/Input';
import Button from '../../../Components/Button/Button';
import AddressInput from '../../../Components/Input/AddressInput';
import axios from 'axios';
import Cadastral from './Cadastral/Cadastral';
import { CircularProgress } from '@mui/material';
import TableCell from '../../../Components/Table/TableCell';
import TableHeader from '../../../Components/Table/TableHeader';
import EmptyBlock from '../../../Components/EmptyBlock/EmptyBlock';

const ObjectSearch = () => {
  const [buttonType, setButtonType] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [cadastralValue, setSearchValueCadastral] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [cadastralData, setCadastralData] = useState(null);

  function getObjectType(type) {
    switch (type) {
      case 'PARCEL':
        return {
          text: 'Земельный участок',
        };
      case 'FLAT':
        return {
          text: 'Помещение',
        };
      case 'OKS':
        return {
          text: 'Здание',
        };
      default:
        return {
          text: 'Неизвестно',
        };
    }
  }

  const getData = (address) => {
    setIsLoading(true);
    axios.post(`https://cadastral.rulink.io/api/v1/ObjectsQuery`,{
        "filter": address,
        "objectType": 0
      },
      {
        headers: {
          "Authorization": 'fc10d937-4292-4288-9254-3487458de29e',
          "traceId": '00000346',
        }
      })
      .then(response => {
        setSearchData(response.data.objects);
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      });
  }

  const getCadastralData = (cadastralNumber) => {
    setIsLoading(true);
    axios.post(`https://cadastral.rulink.io/api/v1/ObjectInfo`,{
        "cadastralNumber": cadastralNumber,
        "objectType": 2
      },
      {
        headers: {
          "Authorization": 'fc10d937-4292-4288-9254-3487458de29e',
          "traceId": '00000346',
        }
      })
      .then(response => {
        setCadastralData(response.data.objects[0]);
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      });
  }

  return (
    <div>
      <div className={styles.card}>
        <CardHeader title="Поиск объекта" />
        <div className={styles.card_body}>
          <div className={styles.search}>
            <Button isDisabled={buttonType === 1} onClick={() => setButtonType(1)} text='Поиск по адресу'/>
            <Button isDisabled={buttonType === 2} onClick={() => setButtonType(2)} text='Поиск по кадастровому номеру'/>
          </div>
          <div className={styles.search}>
            {buttonType === 1 &&
              <AddressInput value={searchValue} setValue={(value) => setSearchValue(value)} label='Поиск объекта по адресу' placeholder="Введите адрес"/>
            }
            {buttonType === 2 &&
              <Input
                onChange={(value) => setSearchValueCadastral(value)}
                label='Поиск объекта по кадастровому номеру'
                placeholder="Введите кадастровый номер"
                mask="00:00:000000:000000000"
                value={cadastralValue}
              />
            }
            <div className={styles.button}>
              <Button onClick={() => {
                if (buttonType === 1) {
                  getData(searchValue?.Value)
                } else if (buttonType === 2) {
                  getCadastralData(cadastralValue)
                }
              }} large text='НАЙТИ'/>
            </div>
          </div>
          {buttonType === 1 &&
            <div>
              {isLoading ?
                <div className={styles.loader}>
                  <CircularProgress color="inherit"/>
                </div>
                :
                <div>
                  {searchData?.length ?
                    <div className={styles.card_wrapper}>
                      <p>Всего объектов: <span>{searchData?.length}</span></p>
                      <tbody className={styles.card_container}>
                      <TableHeader>
                        <TableCell th="Кадастровый номер"/>
                        <TableCell th="Адрес"/>
                        <TableCell th="Вид объекта недвижимости"/>
                      </TableHeader>
                      {searchData?.map((item, index) =>
                          <tr key={index} className={styles.card_item}>
                            <TableCell title={item?.cadastralNumber}/>
                            <TableCell subtitle={item?.address}/>
                            <TableCell subtitle={getObjectType(item?.objectType).text}/>
                          </tr>
                      )}
                      </tbody>
                    </div>
                    :
                    <div>
                      <EmptyBlock title='Не найдено'/>
                    </div>
                  }
                </div>
              }
            </div>
          }
          {buttonType === 2 &&
            <div>
              {isLoading ?
                <div className={styles.loader}>
                  <CircularProgress color="inherit"/>
                </div>
                :
                <div>
                  {cadastralData ?
                    <Cadastral data={cadastralData}/>
                    :
                    <div>
                      <EmptyBlock title='Не найдено'/>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ObjectSearch;
