import BuildingList from "./Pages/Building/List/BuildingList";
import CustomerEdit from "./Pages/Customers/Edit/CustomerEdit";
import PagesLayout from "./Pages/PagesLayout";
import Login from "./Pages/Login/Login";
import NavBar from "./Pages/Navbar/NavBar";
import Register from "./Pages/Register/Register";
import CreateRequest from "./Pages/Reguests/Create/CreateRequest";
import RequestLayout from "./Pages/Reguests/Layout/RequestLayout";
import RequestList from "./Pages/Reguests/List/RequestList";
import RequestResult from "./Pages/Reguests/Result/RequestResult";
import RequestSignature from "./Pages/Reguests/Signature/RequestSignature";
import SalesContract from "./Pages/Reguests/Create/SalesContract";
import EquityContract from "./Pages/Reguests/Create/EquityContract";
import PropertyRegistration from "./Pages/Reguests/Create/PropertyRegistration";
import DemoPage from "./Pages/Demo/DemoPage";
import CustomersTable from "./Pages/Customers/Table/CustomersTable";
import ObjectList from "./Pages/Object/ObjectList/ObjectList";
import ObjectEdit from "./Pages/Object/ObjectEdit/ObjectEdit";
import BuildingCreate from './Pages/Building/Create/BuildingCreate';
import ObjectSearch from './Pages/Object/ObjectSearch/ObjectSearch';

const routes = [
    {
        path: "/register",
        element: <Register />,
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/demo",
        element: <DemoPage />
    },
    {
        path: "/",
        element: <NavBar />,
        children: [
            {
                path: "/",
                element: <PagesLayout />,
                children: [{
                    path: "/request/list",
                    element: <RequestList />
                },
                {
                    path: "/pages/customers",
                    element: <CustomersTable />
                },
                {
                    path: "/pages/buildings",
                    element: <BuildingList />
                },
                {
                    path: "/pages/objects",
                    element: <ObjectList />
                }
                ]
            },

            {
                path: "/customer/edit/:id/:type",
                element: <CustomerEdit />,
            },
            {
                path: "/customer/edit",
                element: <CustomerEdit />,
            },
            {
                path: "/building/create",
                element: <BuildingCreate />
            },
            {
                path: "/building/edit/:id",
                element: <BuildingCreate />
            },
            {
                path: "/object/edit/:id",
                element: <ObjectEdit />
            },
            {
                path: "/object/edit",
                element: <ObjectEdit />
            },
            {
                path: "/object/search",
                element: <ObjectSearch />
            },
            {
                path: "/request",
                element: <RequestLayout />,
                children: [
                    {
                        path: "/request/edit",
                        element: <CreateRequest />,
                        children: [{
                            path: "/request/edit/sales-contract",
                            element: <SalesContract />
                        },

                        {
                            path: "/request/edit/equity",
                            element: <EquityContract />
                        },
                        {
                            path: "/request/edit/property-registration",
                            element: <PropertyRegistration />
                        },
                        ]
                    },
                    {
                        path: "/request/edit/:id",
                        element: <CreateRequest />,
                        children: [{
                            path: "/request/edit/:id/sales-contract",
                            element: <SalesContract />
                        },

                        {
                            path: "/request/edit/:id/equity",
                            element: <EquityContract />
                        },
                        {
                            path: "/request/edit/:id/property-registration",
                            element: <PropertyRegistration />
                        },
                        ]
                    },
                    {
                        path: "/request/signature/:id",
                        element: <RequestSignature />,
                    },

                    {
                        path: "/request/rosreestr/:id",
                        element: <RequestResult />
                    },
                ]
            },
        ],
    }
]
export default routes;