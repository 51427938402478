import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';

import HomesCard from '../Card/HomesCard';
import HomesButton from '../Button/HomesButton';
import HomesModal from '../Modal/HomesModal';
import Statistic from '../Statistic/Statistic';
import { useResidentialComplexService } from '../../../../Services/ResidentialComplexService';
import { ChevronDown } from '../../../../Components/Icon/Icon';

import styles from './homes.board.module.css';

const HomesBoard = ({buildingId, buildingStatistics, complexId, setComplex, isLoading}) => {
  const {getResidentialBuildingLight} = useResidentialComplexService();
  const [selectedId, setSelectedId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenStatistic, setIsOpenStatistic] = useState(false);
  const [statisticType, setStatisticType] = useState(null);
  const [building, setBuilding] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [rightButtonDisabled, setRightButtonDisabled] = useState(false);
  const [leftButtonDisabled, setLeftButtonDisabled] = useState(true);
  const containerRef = useRef(null);
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      const scrollElement = scrollRef.current;
      const currentScrollLeft = scrollElement.scrollLeft;
      const newScrollLeft = currentScrollLeft - 300;

      scrollElement.scrollBy({ left: -300, behavior: 'smooth' });
      setRightButtonDisabled(false);

      setLeftButtonDisabled(newScrollLeft <= 0);
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      const scrollElement = scrollRef.current;
      const currentScrollLeft = scrollElement.scrollLeft;
      const newScrollLeft = currentScrollLeft + 300;

      scrollElement.scrollBy({ left: 300, behavior: 'smooth' });
      setLeftButtonDisabled(false);

      const { scrollWidth, clientWidth } = scrollElement;
      setRightButtonDisabled(newScrollLeft + clientWidth >= scrollWidth);
    }
  };

  const apartments = useMemo(() => building?.Apartments?.filter(apartment => apartment.ApartmentType === 1), [building]);
  const parking = useMemo(() => building?.Apartments?.filter(apartment => apartment.ApartmentType === 2), [building]);
  const pantry = useMemo(() => building?.Apartments?.filter(apartment => apartment.ApartmentType === 3), [building]);
  const commerce = useMemo(() => building?.Apartments?.filter(apartment => apartment.ApartmentType === 4), [building]);
  const utility = useMemo(() => building?.Apartments?.filter(apartment => apartment.ApartmentType === 5), [building]);
  const cycling = useMemo(() => building?.Apartments?.filter(apartment => apartment.ApartmentType === 6), [building]);
  const stroller = useMemo(() => building?.Apartments?.filter(apartment => apartment.ApartmentType === 7), [building]);
  const filteredApartments = useMemo(() => building?.Apartments?.filter(apartment => [3, 4, 5 ,6, 7].includes(apartment?.ApartmentType)), [building]);

  const uniqueFloors = useMemo(() => [...new Set(apartments?.map(obj => obj.Floor))].sort((a, b) => a - b), [apartments]);

  const cardHandler = (type) => {
    if (type) {
      setStatisticType(type)
    } else {
      setStatisticType(null)
    }
    setIsOpenStatistic(true)
  }

  useEffect(() => {
    const checkScroll = () => {
      const scrollContainer = containerRef.current;
      const scrollElement = scrollRef.current;

      if (scrollElement && scrollContainer) {
        if (scrollElement.scrollWidth > scrollContainer.clientWidth) {
          setShowButtons(true);
        } else {
          setShowButtons(false);
        }
      }
    };

    checkScroll();

    window.addEventListener('resize', checkScroll);

    return () => window.removeEventListener('resize', checkScroll);
  }, [apartments]);

  useEffect(() => {
    let timeoutRef = null;

    if (!isLoading || !isOpen) {
      const fetchBuildingData = () => {
        if (buildingId) {
          getResidentialBuildingLight(buildingId).then((build) => {
            if (build[0]?.Apartments !== null) {
              setBuilding(build[0]);
              clearTimeout(timeoutRef);
            } else {
              timeoutRef = setTimeout(fetchBuildingData, 1000);
            }
          });
        } else {
          setBuilding(null);
        }
      };

      fetchBuildingData();
    }

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [isLoading, buildingId, isOpen]);

  const cardsData = [
    { type: 'purple', title: 'Главная', total: buildingStatistics?.AllObjectCount, amount: buildingStatistics?.UDSObjectCount, id: 1 },
    { type: 'yellow', title: 'Уведомления о приемке направлены', total: buildingStatistics?.UDSObjectCount, amount: buildingStatistics?.StatusCounts[2] || 0, id: 2 },
    { type: 'red', title: 'Записано на приемку', total: buildingStatistics?.UDSObjectCount, amount: buildingStatistics?.StatusCounts[3] || 0, id: 3 },
    { type: 'info', title: 'Объектов принято', total: buildingStatistics?.UDSObjectCount, amount: buildingStatistics?.StatusCounts[4] || 0, id: 4 },
    { type: 'danger', title: 'Объекты с замечаниями', total: buildingStatistics?.UDSObjectCount, amount: buildingStatistics?.StatusCounts[5] || 0, id: 5 },
    { type: 'warning', title: 'Акты приема-передачи загружены', total: buildingStatistics?.UDSObjectCount, amount: buildingStatistics?.StatusCounts[6] || 0, id: 6 },
    { type: 'blue', title: 'Находятся на регистрации', total: buildingStatistics?.AllObjectCount, amount: buildingStatistics?.StatusCounts[7] || 0, id: 7 },
    { type: 'error', title: 'Приостановлено', total: buildingStatistics?.AllObjectCount, amount: buildingStatistics?.StatusCounts[8] || 0, id: 8 },
    { type: 'error', title: 'Возвращено без рассмотрения', total: buildingStatistics?.AllObjectCount, amount: buildingStatistics?.StatusCounts[9] || 0, id: 9 },
    { type: 'success', title: 'Права зарегистрированы', total: buildingStatistics?.AllObjectCount, amount: buildingStatistics?.StatusCounts[10] || 0, id: 10 },
  ];

  return (
    <div>
      {building === null ?
        <div className={styles.loader}>
          <CircularProgress color="inherit"/>
        </div>
        :
        <div>
          <div>
            { apartments?.length !== 0 && <p className={styles.info}>Количество помещений с типом квартира: {apartments?.length}</p>}
            { parking?.length !== 0 && <p className={styles.info}>Количество машиномест: {parking?.length}</p>}
            { filteredApartments?.length !== 0 && <p className={styles.info}>Количество нежилых помещений: {filteredApartments?.length}</p>}
            { <p className={styles.total}>Всего помещений: {building?.Apartments?.length}</p>}
          </div>
          <div className={styles.wrapper}>
            {cardsData.map(card => (
              <HomesCard
                key={card.id}
                type={card.type}
                title={card.title}
                total={card.total}
                amount={card.amount}
                onClick={() => cardHandler(card.id)}
                isMain={card.id === 1}
              />
            ))}
          </div>
          <div className={styles.title_wrapper}>
            <h4 className={styles.title_apartment}>{ `Жилые помещения (Квартиры / ${ apartments?.length })` }</h4>
            {showButtons && (
              <div className={styles.scroll_wrapper}>
                <button disabled={leftButtonDisabled} onClick={scrollLeft} className={styles.scroll_button}>
                  <ChevronDown className={styles.arrow_left}/>
                </button>
                <button disabled={rightButtonDisabled} onClick={scrollRight} className={styles.scroll_button}>
                  <ChevronDown className={styles.arrow_right}/>
                </button>
              </div>
            )}
          </div>
          <div className={styles.container}>
            <div className={styles.floors}>
              {uniqueFloors?.map((floor, index) => (
                <HomesButton key={index} type='default' text={`Этаж ${floor}`} />
              ))}
            </div>
            <div ref={containerRef} className={styles.wrapper_apartments}>
              <div ref={scrollRef} className={styles.apartments}>
                {building?.EntranceCount && Array.from({ length: building?.EntranceCount }).map((_, apartment) => (
                  <div className={styles.apartment} key={apartment}>
                    <label className={styles.apartment_title}>Секция {apartment + 1}</label>
                    {uniqueFloors?.map((entrance) => (
                      <div style={{display: 'flex', height: '40px', gap: '12px'}} key={entrance}>
                        {apartments.filter(obj => obj.Floor === entrance && obj.Entrance === apartment + 1)
                          .map(obj => (
                            <div key={obj.Id} className={styles.block} style={{ '--columns': `${ building?.doors }` }}>
                              <HomesButton
                                type={!obj?.OwnersCount > 0 ? 'warning' : 'success'}
                                obj={obj}
                                text={ obj.Number }
                                onClick={() => {
                                  setSelectedId(obj.Id)
                                  setIsOpen(true)
                                }}
                                apartment={obj}
                              />
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {parking?.length !== 0 &&
            <div>
              <h4 className={styles.title}>{ `Машиноместа (${ parking?.length })` }</h4>
              <div className={styles.others}>
                {parking?.map((item) => (
                  <HomesButton
                    type={!item?.OwnersCount > 0 ? 'warning' : 'success'}
                    text={item.Number}
                    onClick={() => {
                      setSelectedId(item.Id)
                      setIsOpen(true)
                    }}
                    apartment={item}
                  />
                ))}
              </div>
            </div>
          }
          {pantry?.length !== 0 &&
            <div>
              <h4 className={styles.title}>{ `Нежилые помещения (Кладовые / ${ pantry?.length })` }</h4>
              <div className={styles.others}>
                {pantry?.map((item, index) => (
                  <HomesButton
                    key={index}
                    type={!item?.OwnersCount > 0 ? 'warning' : 'success'}
                    text={item.Number}
                    onClick={() => {
                      setSelectedId(item.Id)
                      setIsOpen(true)
                    }}
                    apartment={item}
                  />
                ))}
              </div>
            </div>
          }
          {commerce?.length !== 0 &&
            <div>
              <h4 className={styles.title}>{ `Нежилые помещения (Коммерция / ${ commerce?.length })` }</h4>
              <div className={styles.others}>
                {commerce?.map((item, index) => (
                  <HomesButton
                    key={index}
                    type={!item?.OwnersCount > 0 ? 'warning' : 'success'}
                    text={item.Number}
                    onClick={() => {
                      setSelectedId(item.Id)
                      setIsOpen(true)
                    }}
                    apartment={item}
                  />
                ))}
              </div>
            </div>
          }
          {utility?.length !== 0 &&
            <div>
              <h4 className={styles.title}>{ `Нежилые помещения (Технические помещения / ${ utility?.length })` }</h4>
              <div className={styles.others}>
                {utility?.map((item) => (
                  <HomesButton
                    text={item.Number}
                    type={!item?.OwnersCount > 0 ? 'warning' : 'success'}
                    onClick={() => {
                      setSelectedId(item.Id)
                      setIsOpen(true)
                    }}
                    apartment={item}
                  />
                ))}
              </div>
            </div>
          }
          {cycling?.length !== 0 &&
            <div>
              <h4 className={styles.title}>{ `Нежилые помещения (Велосипедная / ${ cycling?.length })` }</h4>
              <div className={styles.others}>
                {cycling?.map((item) => (
                  <HomesButton
                    text={item.Number}
                    type={!item?.OwnersCount > 0 ? 'warning' : 'success'}
                    onClick={() => {
                      setSelectedId(item.Id)
                      setIsOpen(true)
                    }}
                    apartment={item}
                  />
                ))}
              </div>
            </div>
          }
          {stroller?.length !== 0 &&
            <div>
              <h4 className={styles.title}>{ `Нежилые помещения (Колясочная / ${ stroller?.length })` }</h4>
              <div className={styles.others}>
                {stroller?.map((item) => (
                  <HomesButton
                    text={item.Number}
                    type={!item?.OwnersCount > 0 ? 'warning' : 'success'}
                    onClick={() => {
                      setSelectedId(item.Id)
                      setIsOpen(true)
                    }}
                    apartment={item}
                  />
                ))}
              </div>
            </div>
          }
          <HomesModal
            complexId={complexId}
            selectedId={selectedId}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setComplex={setComplex}
          />
          <Statistic isOpen={isOpenStatistic} setIsOpen={setIsOpenStatistic} type={statisticType} buildingId={building?.Id}/>
        </div>
      }
    </div>
  );
};

export default HomesBoard;
