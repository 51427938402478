import React from 'react';
import styles from './cadastral.module.css';

const Cadastral = ({data}) => {

  function getStatus(status) {
    switch (status) {
      case '0':
        return {
          text: 'Не актуально',
        };
      case '1':
        return {
          text: 'Актуально',
        };
      default:
        return {
          text: 'Неизвестно',
        };
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  return (
    <div className={styles.container}>
      <p className={styles.title}>{data?.objectTypeDesctiption}</p>
      <div className={styles.item}>
        <span>Кадастровый номер:</span>
        <span>{data?.cadastralNumber}</span>
      </div>
      <div className={styles.item}>
        <span>Адрес:</span>
        <span>{data?.address?.fullAddress}</span>
      </div>
      <div className={styles.item}>
        <span>Статус объекта:</span>
        <span>{getStatus(data?.status).text}</span>
      </div>
      <div className={styles.item}>
        <span>Площадь:</span>
        <span>{data?.area}</span>
      </div>
      <div className={styles.item}>
        <span>Дата регистрации объекта:</span>
        <span>{formatDate(data?.registrationDate)}</span>
      </div>
      <div className={styles.item}>
        <span>Назначение:</span>
        <span>{data?.purpose}</span>
      </div>
      <div className={styles.item}>
        <span>Этаж:</span>
        <span>{data?.levelFloor}</span>
      </div>
      <p className={styles.title}>Права собственности</p>
    </div>
  );
};

export default Cadastral;
