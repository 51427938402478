import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { CircularProgress } from '@mui/material';

import { usePostNotificationsService } from '../../../../Services/PostNotifications/PostNotificationsService';
import { useResidentialComplexService } from '../../../../Services/ResidentialComplexService';
import { statisticTypes } from '../../../../constants/constants';
import { HttpContext } from '../../../../HttpContext';
import TableHeader from '../../../../Components/Table/TableHeader';
import TableCell from '../../../../Components/Table/TableCell';
import TableContainer from '../../../../Components/Table/TableContainer';
import TooltipSmallText from '../../../../Components/Tooltip/TooltipSmallText';
import Badge from '../../../../Components/Badge/Badge';
import Input from '../../../../Components/Input/Input';
import ModalCenterFull from '../../../../Components/ModalCenter/ModalCenterFull';
import getStatus from '../../../../Services/PostNotifications/PostNotificationStatuses';
import TooltipText from '../../../../Components/Tooltip/TooltipText';

import styles from './statistic.module.css';

const Statistic = ({isOpen, setIsOpen, type, buildingId}) => {
  const {getResidentialBuilding} = useResidentialComplexService();
  const {getLetterStatus} = usePostNotificationsService();
  const { state } = useContext(HttpContext);
  const {flSubjects, ulSubjects} = state;

  const objectTypes = [
    {
      value: 1,
      text: "Жилое помещение (Квартира)",
    },
    {
      value: 2,
      text: "Машиноместа",
    },
    {
      value: 3,
      text: "Нежилые помещения (Кладовые)",
    },
    {
      value: 4,
      text: "Нежилые помещения (Коммерция)",
    },
    {
      value: 5,
      text: "Нежилые помещения (Технические помещения)",
    },
    {
      value: 6,
      text: "Нежилые помещения (Велосипедная)",
    },
    {
      value: 7,
      text: "Нежилые помещения (Колясочная)",
    }
  ];

  const sendingTypes = [
    {
      value: 1,
      text: "Электронная почта",
    },
    {
      value: 2,
      text: "Электр.заказн.письмо",
    },
    {
      value: 3,
      text: "Бумажн.заказн.письмо",
    },
  ];

  const [building, setBuilding] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [trackNumbers, setTrackNumbers] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        if (buildingId) {
          const build = await getResidentialBuilding(buildingId);

          if (type === 1) {
            setBuilding(build[0]);
          } else {
            const filteredApartments = build[0]?.Apartments?.flatMap(apartment => {
              return apartment?.ApartmentStatus === type
                ? [{ ...apartment }]
                : [];
            });

            setBuilding({
              ...build[0],
              Apartments: filteredApartments,
            });
          }
        } else {
          setBuilding(null);
        }
      } catch (error) {
        console.error("Error fetching building data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [buildingId, type]);


  useEffect(() => {
    building?.Apartments?.forEach(apartment => {
      apartment?.Owners?.forEach(owner => {
        if (owner?.RequestCode) {
          getTrackNumber(owner.RequestCode, owner.Id);
        }
      });
    });
  }, [building]);

  const getTrackNumber = async (requestCode, id) => {
    try {
      const req = await getLetterStatus(requestCode);
      if (req) {
        setTrackNumbers(prevState => ({
          ...prevState,
          [id]: req.MailId
        }));
      }
    } catch (error) {
      console.error(`Ошибка получения трек-номера для ${requestCode}:`, error);
    }
  };

  return (
    <ModalCenterFull isOpen={isOpen} setIsOpen={setIsOpen} title={
      <div className={styles.header_title}>
        <h2>{ statisticTypes.find(item => item.type === type)?.text }</h2>
        <div className={styles.search}>
          <Input
            placeholder='Поиск по участникам'
            value={search}
            onChange={(value) => setSearch(value)}
          />
        </div>
      </div>
    }>
      <TableContainer className={styles.container}>
        <TableHeader className={styles.head}>
          <TableCell th="№"/>
          {(type === 1 || type === 10) && <TableCell th="Информация о помещении"/>}
          {type === 10 && <TableCell th="Застройщик"/>}
          <TableCell th="Участник"/>
          {(type === 1 || type === 2) && <TableCell th="Адрес отправки"/>}
          {type === 10 && <TableCell th="КУВД/ВС"/>}
          {type === 2 && <TableCell th="Тип отправки"/>}
          {type === 2 && <TableCell th="Трек номер"/>}
          {(type === 1 || type === 2) && <TableCell th="Статус"/>}
          {type === 10 && <TableCell th="Выписка из ЕГРН"/>}
        </TableHeader>
        {!isLoading &&
          <tbody>
          {building?.Apartments?.length > 0 && building.Apartments.map((item, index) =>
            <tr
              key={item?.Id}
              onClick={(e) => {
                if (type === 1 || type === 10) {
                  e.preventDefault();
                  window.open(`/building/edit/${building?.ResidentialComplexId}?buildingId=${building?.Id}&apartment=${item?.Id}`, '_blank');
                } else if (type === 2) {
                  window.open(`/building/edit/${item?.ResidentialComplexId}?buildingId=${item?.BuildingId}&apartment=${item?.Id}&ownerId=${item?.Owners[0]?.Id}`, '_blank');
                }
              }}
              className={cn(styles.link, {[styles.link_hover]: type === 1 || type === 2 || type === 10 })}
            >
              <TableCell
                title={item?.Number}
                subtitle={item?.EstateObject?.Type === 1
                  ?
                  <span>под.{item?.Entrance}, эт.{item?.Floor}</span>
                  :
                  null
                }
              />
              {(type === 1 || type === 10) &&
                <TableCell
                  title={<span>{ objectTypes.find((type) => type.value === Number(item?.ApartmentType))?.text }</span>}
                  subtitle={
                    <div className={styles.column}>
                      <span>{item?.EstateObject?.CadNumber} ({item?.EstateObject?.Area} кв.м)</span>
                    </div>
                  }
                />
              }
              {type === 10 &&
                <TableCell
                  th={item?.Owners?.length > 0 ?
                    <span>-</span>
                    :
                    <TooltipText text={state?.builder} maxLength='20'/>
                  }
                />
              }
              <TableCell th={item?.Owners?.length > 0 ?
                <div className={styles.column}>
                  {item?.Owners.map((owner) =>
                    owner?.FlOwnerId ?
                      <span key={owner?.FlOwnerId}>
                      {`${flSubjects?.find((x) => x.Id === Number(owner.FlOwnerId))?.Lastname}
                        ${flSubjects?.find((x) => x.Id === Number(owner.FlOwnerId))?.Firstname}
                        ${flSubjects?.find((x) => x.Id === Number(owner.FlOwnerId))?.Middlename}
                        `}
                    </span>
                      :
                      <span>
                      {ulSubjects?.find((x) => x.Id === Number(owner.UlOwnerId))?.Name}
                    </span>
                  )
                  }
                </div>
                :
                <span>-</span>
              }/>
              {(type === 1 || type === 2) &&
                <TableCell th={item?.Owners?.length > 0 ?
                  <div className={styles.column}>
                    {item?.Owners.map((owner) =>
                      <TooltipSmallText text={owner?.PostalAddress ? owner?.PostalAddress : '-'} maxLength={type === 1 ? '20' : '30'}/>
                    )}
                  </div>
                  :
                  <span>-</span>
                }/>
              }
              {type === 10 &&
                <TableCell th={
                  <span>-</span>
                }/>
              }
              {type === 2 &&
                <TableCell th={item?.Owners?.length > 0 ?
                  <div className={styles.column}>
                    {item?.Owners.map((owner) =>
                        <span>
                      {owner?.SendingType && owner?.IsSent
                        ? sendingTypes.find(type => type.value === owner.SendingType)?.text || "-"
                        : "-"
                      }
                    </span>
                    )}
                  </div>
                  :
                  <span>-</span>
                }/>
              }
              {type === 2 &&
                <TableCell th={
                  item?.Owners?.length > 0 ? (
                    <div className={styles.column}>
                      {item?.Owners.map((owner) => (
                        trackNumbers[owner.Id] ?
                          <span key={owner.Id}>
                            <a
                              className={styles.track}
                              target='_blank'
                              rel="noopener noreferrer"
                              href={`https://www.pochta.ru/tracking?barcode=${trackNumbers[owner.Id]}`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {trackNumbers[owner.Id]}
                            </a>
                          </span>
                          :
                          <span>-</span>
                      ))}
                    </div>
                  ) : (
                    <span>-</span>
                  )
                }/>
              }
              {(type === 1 || type === 2) &&
                <TableCell th={item?.Owners?.length > 0 ?
                  <div className={styles.column}>
                    {item?.Owners.map((owner) =>
                      <Badge type={ getStatus(owner?.State).badgeType } text={getStatus(owner?.State).text}/>
                    )}
                  </div>
                  :
                  <Badge type={item?.ApartmentStatus === 10 ? 'success' : 'primary' } text={ item?.ApartmentStatus === 10 ? 'Права зарегистрированы' : 'Готово для регистрации' }/>
                }/>
              }
              {type === 10 &&
                <TableCell th={
                  <span>-</span>
                }/>
              }
            </tr>
          )}
          </tbody>
        }
      </TableContainer>
      {isLoading &&
        <div className={styles.circular}>
          <CircularProgress color="inherit"/>
        </div>
      }
    </ModalCenterFull>
  );
};

export default Statistic;