import React, { useEffect, useState } from "react";
import FileInput from "../../../Components/FileInput/FileInput";
import Modal from "../../../Components/Modal/Modal";
import Select from "../../../Components/Select/Select";
import SmallCard from "../../../Components/SmallCard/SmallCard";
import avatar from "../../../images/Avatar_2.svg"
import Input from "../../../Components/Input/Input";
import pdfIcon from "../../../icons/PdfIcon.svg"
import EmptyBlock from "../../../Components/EmptyBlock/EmptyBlock";
import UlSubjectCard from "../../../Components/UlSubjectCard/UlSubjectCard";
import DocumentCard from "../../../Components/DocumentCard/DocumentCard";
import useRequestContext from '../useRequestContext';

import styles from './add.ul.module.css'
import { useRequestService } from '../../../Services/Request/RequestService';

export default function AddUlSubjectModal({ ulSubject, isOpen, setOpen }) {
  const [document, setDocument] = useState(null);
  const {request, documentTypes, getRequest, addUlAgent} = useRequestContext()
  const {updateUlAgent} = useRequestService()
  const [mainAgent, setMainAgent] = useState();
  const [subject, setSubject] = useState({
    RequestId: null,
    TypeId: 0,
    FractionNumerator: null,
    FractionDenominator: null,
    UlAgentId: null
  })

  const onSaveUl = () => {
    if (mainAgent) {
      addUlAgent(subject).then(() => {
        getRequest(request.Id)
      })
    } else {
      updateUlAgent({ ...subject, Id: ulSubject?.Id }).then(() => {
        getRequest(request.Id)
      })
    }
  }

  useEffect(() => {
    const mainAgent = ulSubject?.Agents?.find(x => x.IsMain);
    setMainAgent(mainAgent)
  }, [ulSubject])

  useEffect(() => {
    if (isOpen) {
      setDocument(mainAgent ? mainAgent?.Document : ulSubject?.Document)
      setSubject({...subject, RequestId: request?.Id , UlAgentId: mainAgent ? mainAgent.Id : ulSubject?.UlAgent?.UlAgentId, FractionNumerator: ulSubject?.Numerator, FractionDenominator: ulSubject?.Denominator})
    } else {
      setDocument(null)
      setSubject({
        RequestId: null,
        TypeId: 0,
        FractionNumerator: null,
        FractionDenominator: null,
        UlAgentId: null
      })
    }
  }, [isOpen, mainAgent])

    return (
      <Modal onSave={onSaveUl} title={`Добавление участника (Юридическое лицо)`} isOpen={isOpen} setOpen={setOpen}>
        <label>Участник</label>
        <UlSubjectCard ulSubject={ulSubject} />
        {request?.RightType?.code === '001002000000'
          ?
          <div>
            <label>Доля</label>
            <div className={styles.wrapper}>
              <Input value={subject?.FractionNumerator} onChange={(value) => setSubject({...subject, FractionNumerator: value ? Number(value) : null})}/>
              <span>/</span>
              <Input value={subject?.FractionDenominator} onChange={(value) => setSubject({...subject, FractionDenominator: value ? Number(value) : null})}/>
            </div>
          </div>
          :
          null
        }
        {/* <label>Основной представитель</label>
            {
                ulSubject?.Agents?.length == 0 &&
                <EmptyBlock title="Представители отсутвуют"></EmptyBlock>
            } */}
        {/* {
                mainAgent &&
                <SmallCard
                    title={mainAgent.FioGenitive}
                    subtitle={""}
                    avatar={avatar}
                />

            } */}
        <label>Документ, подтверждающий полномочия</label>
        {document?.FileName ?
            <DocumentCard document={document}/>
            :
            <EmptyBlock title="Документ, подтверждающий полномочия"/>
        }
        <label>Электронная подпись</label>
        {document?.SigFileName ?
            <SmallCard avatar={pdfIcon} type="default" title={document?.SigFileName}/>
            :
            <EmptyBlock title="Электронная подпись"/>
        }
        <Select value={document?.Type?.code} disabled label="Тип документа">
          {documentTypes.map((x) =>
            <option value={x.code}>{x.name}</option>
          )}
        </Select>
        <Input value={document?.Date} disabled onChange={(value) => setDocument({...document, Date: value})} label="Дата создания"/>
      </Modal>
    )
}
