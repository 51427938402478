import { useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';

import close from '../../icons/XCircle.svg';
import styles from "./modal.center.module.css";

export default function ModalCenterFull({ isOpen, children, setIsOpen, title }) {
  const modalRef = useRef(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleEscKeyPress = useCallback(
    (event) => event.key === 'Escape' && closeModal(),
    []
  );

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    if (isOpen) {
      document.addEventListener('keydown', handleEscKeyPress);
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('keydown', handleEscKeyPress);
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, handleEscKeyPress]);

  return (
    <div className={cn(styles.modal_wrapper, {[styles.wrapper_hidden]: !isOpen })}>
      <div className={cn(styles.modal_full, {[styles.modal_hidden]: !isOpen})} ref={modalRef}>
        <img className={styles.close_button} onClick={closeModal} src={close}/>
        <div className={styles.header_sticky}>
          {title && title}
        </div>
        <div className={styles.body_full}>
          {children}
        </div>
      </div>
    </div>
  );
}
