import React, { useContext, useEffect, useRef, useState } from 'react';
import { Skeleton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import Input from "../../../../Components/Input/Input";
import Select from "../../../../Components/Select/Select";
import Check from "../../../../Components/Check/Check";
import Button from "../../../../Components/Button/Button";
import SmallCard from "../../../../Components/SmallCard/SmallCard";
import FileInput from "../../../../Components/FileInput/FileInput";
import Modal from "../../../../Components/Modal/Modal";
import { mimeTypes } from "../../../../constants/constants";
import { useFlService } from "../../../../Services/FlSubjectService";
import AddressInput from "../../../../Components/Input/AddressInput";
import { useResidentialComplexService } from '../../../../Services/ResidentialComplexService';
import { useUlService } from '../../../../Services/UlSubjectService';
import useFileService from '../../../../Services/FileService';
import Participant from './Participant/Participant';
import { ArrowLeft } from '../../../../Components/Icon/Icon';
import Badge from '../../../../Components/Badge/Badge';
import { HttpContext } from '../../../../HttpContext';
import Signature from './Signature/Signature';
import getStatus from '../../../../Services/PostNotifications/PostNotificationStatuses';

import pdf from "../../../../icons/PdfIcon.svg";
import plus from "../../../../icons/plus-violet.svg";
import avatar from "../../../../images/Avatar_2.svg";
import styles from "./homes.modal.module.css";

export default function HomesModal({ selectedId, isOpen, setIsOpen, complexId, setComplex}) {
  const [isSharer, setIsSharer] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [certificateTechnical, setCertificateTechnical] = useState(false);
  const [isSignature, setIsSignature] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ulSubjects, setUlSubjects] = useState([]);
  const [flSubjects, setFlSubjects] = useState([]);
  const [apartment, setApartment] = useState(null);
  const [selectedObjectType, setSelectedObjectType] = useState('');
  const [openParticipant, setOpenParticipant] = useState(false);
  const [selectedOwnerId, setSelectedOwnerId] = useState(null);
  const participantRef = useRef(null);
  const [owner, setOwner] = useState(null);
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);

  const { getFlSubjectsPage } = useFlService();
  const { getUlSubjectsPage } = useUlService();
  const {updateResidentialApartment, getResidentialApartment, getResidentialComplex, regNewRight} = useResidentialComplexService();
  const {downloadStorageFile, uploadFile} = useFileService();
  const {createOwner, updateOwner} = useResidentialComplexService();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const apartmentId = queryParams.get('apartment');
  const ownerId = queryParams.get('ownerId');
  const { state } = useContext(HttpContext);

  const clearHistory = () => {
    if (window.location.search) {
      const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    }
  }

  useEffect(() => {
    if (apartmentId) {
      setTimeout(() => {
        setIsOpen(true);
      }, 1500);
    }
  }, [apartmentId]);


  useEffect(() => {
    if (!isOpen) {
      setApartment(null)
      setOwner(null)
      setSelectedOwnerId(null)
    } else {
      setIsLoading(true)
      getResidentialApartment(apartmentId ? apartmentId : selectedId).then(selectedApartment => {
        getFlSubjectsPage(1, 1000).then(res => setFlSubjects(res.Data));
        getUlSubjectsPage(1, 1000).then(res => setUlSubjects(res.Data))
        setApartment(selectedApartment);
        if (ownerId) {
          setSelectedOwnerId(Number(ownerId))
          setOpenParticipant(true)
        } else {
          setSelectedOwnerId(selectedApartment?.Owners?.length === 1 ? selectedApartment?.Owners[0].Id : null);
        }
        selectedApartment?.Owners?.length > 0 ? setIsSharer(true) : setIsSharer(false);
        setIsLoading(false)
      }).then(() => clearHistory())
    }
  }, [isOpen])

  useEffect(() => {
    setSelectedObjectType(objectTypes.find((item) => item.value === Number(apartment?.ApartmentType),)?.text)
  }, [apartment?.ApartmentType])

  useEffect(() => {
    setIsSignature(!!(apartment?.ActFileName && apartment?.ActSigFileName));
    setCertificateTechnical(!!apartment?.TechActAcceptanceFileName);
    setCertificate(!!apartment?.ActFileName);
  }, [apartment?.ActFileName, apartment?.ActSigFileName, apartment?.TechActAcceptanceFileName]);


  useEffect(() => {
    if (openParticipant && participantRef.current) {
      participantRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (!openParticipant && selectedId) {
      getResidentialApartment(selectedId).then(selectedApartment => {
        setApartment(selectedApartment);
        if(selectedApartment?.Owners?.length > 0) {
          setIsSharer(true);
          setOwner(null)
        } else {
          setIsSharer(false)
        }
        setSelectedOwnerId(selectedApartment?.Owners?.length === 1 ? selectedApartment?.Owners[0].Id : null);
      })
    }
  }, [openParticipant]);

  const handleOwner = () => {
    if (selectedOwnerId) {
      return updateOwner({ ...owner, ApartmentId: apartment?.Id, SendingType: owner?.SendingType ? owner?.SendingType : 2 })
        .then((id) => {
          setSelectedOwnerId(id);
          return updateResidentialApartment(apartment);
        })
        .then(() => getResidentialApartment(selectedId))
        .then((selectedApartment) => {
          setApartment(selectedApartment);
        });
    } else {
      return createOwner(owner)
        .then((id) => {
          setSelectedOwnerId(id);
          return updateResidentialApartment(apartment);
        })
        .then(() => getResidentialApartment(selectedId))
        .then((selectedApartment) => {
          setApartment(selectedApartment);
        });
    }
  };


  const changeSignature = (formData) => {
    uploadFile(formData).then(id => {
      setApartment({
        ...apartment,
        ActSigFileId: id,
        ActSigFileName: `${apartment?.ActFileName}.sig`,
      })
    }).then(() => setOpenSignatureModal(false))
  }

  const objectTypes = [
    {
      value: 1,
      text: "Жилое помещение (Квартира)",
    },
    {
      value: 2,
      text: "Машиноместо",
    },
    {
      value: 3,
      text: "Нежилое помещение (Кладовая)",
    },
    {
      value: 4,
      text: "Нежилое помещение (Коммерция)",
    },
    {
      value: 5,
      text: "Нежилое помещение (Техническое помещение)",
    },
    {
      value: 6,
      text: "Нежилое помещение (Велосипедная)",
    },
    {
      value: 7,
      text: "Нежилое помещение (Колясочная)",
    },
  ];

  return (
    <Modal
      title={
      <div className={styles.title_modal}>
        <div>
          {selectedObjectType ? selectedObjectType : 'Помещение'} №{apartment?.Number}
        </div>
        {apartment?.ApartmentStatus === 10 ?
          <Badge type='success' text='Права зарегистрированы'/>
          :
          null
        }
      </div>}
      isOpen={isOpen}
      setOpen={setIsOpen}
      skeleton={isLoading}
      onSave={() => {
        if(apartment?.Owners?.length === 0 && owner) {
          handleOwner()
        } else {
          updateResidentialApartment(apartment).then(() => {
            getResidentialComplex(complexId).then((complex) => {
              setComplex(complex.data[0])
            })
          })
        }
      }}
      notClose={apartment?.Owners?.length === 0 && owner}
      button={
        apartment?.RequestId
          ?
          <Button
            onClick={() => {
              navigate(`/request/edit/${apartment?.RequestId}`)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            text="Перейти в заявку"
          />
          :
        ((apartment?.PermitFileName && !isSharer) || (isSharer && apartment?.ActFileName)) && (
          <Button
            isDisabled={createButtonDisabled}
            onClick={() => {
              setCreateButtonDisabled(true);
              if (apartment?.Owners?.length === 0 && owner) {
                handleOwner().then(() => {
                  regNewRight(apartment?.Id).then((id) => {
                    navigate(`/request/edit/${id}/property-registration`);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }).catch(() => setCreateButtonDisabled(false))
                }).catch(() => setCreateButtonDisabled(false))
              } else {
                updateResidentialApartment(apartment).then(() => {
                  getResidentialComplex(complexId).then((complex) => {
                    setComplex(complex.data[0]);
                    regNewRight(apartment?.Id).then((id) => {
                      navigate(`/request/edit/${id}/property-registration`);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }).catch((x) => console.log(x))
                  });
                }).catch(() => setCreateButtonDisabled(false))
              }
            }}
            text="Создать заявку на регистрацию права"
          />
        )
      }
    >
      {isOpen && openParticipant ?
        <div ref={participantRef} className={styles.participant}>
          <button
            className={styles.exit}
            onClick={() => setOpenParticipant(!openParticipant)}
          >
            <ArrowLeft/>
            <p className={styles.titleText}>{`${selectedObjectType ? selectedObjectType : 'Помещение'} №${apartment?.Number}`}</p>
          </button>
          <Participant
            owner={owner}
            setOwner={setOwner}
            ownerId={selectedOwnerId}
            setOwnerId={setSelectedOwnerId}
            apartment={apartment}
            setApartment={setApartment}
            setOpenParticipant={setOpenParticipant}
            setIsSharer={setIsSharer}
          />
          <div className={styles.save}>
            <Button
              onClick={() => {
                handleOwner()
                setOpenParticipant(!openParticipant)
              }}
              text='Сохранить изменения'
            />
          </div>
        </div>
          :
        (
        <>
        <Input
          value={apartment?.EstateObject?.CadNumber}
          label="Кадастровый номер"
          placeholder="Введите кадастровый номер"
          mask="00:00:000000:000000000"
          onChange={(value) =>
            setApartment({
              ...apartment,
              EstateObject: {
                ...apartment.EstateObject,
                CadNumber: value,
              },
            })
          }
          skeleton={isLoading}
          disabled={apartment?.RequestId}
        />
        <Select
          value={apartment?.ApartmentType}
          label="Вид объекта"
          placeholder="Выберите вид объекта"
          onChange={(value) =>
            setApartment({
              ...apartment,
              ApartmentType: value,
            })
          }
          skeleton={isLoading}
          disabled={apartment?.RequestId}
        >
          {objectTypes.map((item, index) => (
            <option key={index} value={item.value}>
              {item.text}
            </option>
          ))}
        </Select>
        <AddressInput
          value={apartment?.EstateObject?.Address}
          setValue={(value) =>
            setApartment({
              ...apartment,
              EstateObject: {
                ...apartment.EstateObject,
                Address: value.Note,
              },
            })
          }
          skeleton={isLoading}
          disabled={apartment?.RequestId}
        />
        <Input
          value={apartment?.EstateObject?.Area}
          label="Площадь помещения"
          placeholder="80 м2"
          onChange={(value) =>
            setApartment({
              ...apartment,
              EstateObject: {
                ...apartment.EstateObject,
                Area: value,
              },
            })
          }
          skeleton={isLoading}
          disabled={apartment?.RequestId}
        />
        <Input label='Застройщик' disabled value={state?.builder || apartment?.RequestId} skeleton={isLoading}/>
        <Input label='Представитель' disabled value={`${state?.participant?.name} (${state?.participant?.position})`} skeleton={isLoading}/>
        <Input
          value={apartment?.EstateObject?.Description}
          label="Описание"
          isTextarea
          onChange={(value) =>
            setApartment({
              ...apartment,
              EstateObject: {
                ...apartment.EstateObject,
                Description: value,
              },
            })
          }
          skeleton={isLoading}
          disabled={apartment?.RequestId}
        />
        <div>
          {!isSharer &&
            <div>
              <div className={styles.documents}>
                <label className={styles.label}>
                  Разрешение на ввод в эксплуатацию
                </label>
                {apartment?.PermitFileName ? (
                  <SmallCard
                    avatar={pdf}
                    title={apartment?.PermitFileName}
                    onClick={() => downloadStorageFile(apartment?.PermitFileId, apartment?.PermitFileName)}
                  />
                ) : (
                  <FileInput
                    accept={[mimeTypes.Pdf]}
                    onChange={(value) => setApartment({
                      ...apartment,
                      PermitFile: value,
                      PermitFileName: value.name
                    })}
                  />
                )}
              </div>
            </div>
          }
          <Check disabled={owner?.IsSent || apartment?.Owners?.length > 0} selected={isSharer} label="Объект с УДС" onClick={() => setIsSharer(!isSharer)} skeleton={isLoading}/>
          {isSharer &&
            <div className={styles.sharer}>
              {apartment?.Owners?.length > 0 ?
                <div className={styles.owner}>
                  {apartment.Owners.map((owner) =>
                    owner.FlOwnerId ?
                      <SmallCard
                        avatar={avatar}
                        title={`${flSubjects?.find((x) => x.Id === Number(owner.FlOwnerId))?.Lastname} 
                      ${flSubjects?.find((x) => x.Id === Number(owner.FlOwnerId))?.Firstname} 
                      ${flSubjects?.find((x) => x.Id === Number(owner.FlOwnerId))?.Middlename}
                      `}
                        subtitle='Физическое лицо'
                        onClick={() => {
                          setOpenParticipant(!openParticipant);
                          setSelectedOwnerId(owner.Id)
                        }}
                        key={owner.Id}
                        badge={<Badge type={ getStatus(owner?.State).badgeType } text={getStatus(owner?.State).text}/>}
                        skeleton={!flSubjects?.find((x) => x.Id === Number(owner.FlOwnerId))?.Lastname}
                      />
                      :
                      <SmallCard
                        avatar={avatar}
                        title={`${ulSubjects?.find((x) => x.Id === Number(owner.UlOwnerId))?.Name}`}
                        subtitle='Юридическое лицо'
                        onClick={() => {
                          setOpenParticipant(!openParticipant);
                          setSelectedOwnerId(owner.Id)
                        }}
                        key={owner.Id}
                        badge={<Badge type={ getStatus(owner?.State).badgeType } text={getStatus(owner?.State).text}/>}
                        skeleton={!ulSubjects?.find((x) => x.Id === Number(owner.UlOwnerId))?.Name}
                      />
                  )}
                </div>
                :
                isLoading
                ?
                  <SmallCard
                    skeleton={isLoading}
                  />
                :
                <Participant
                  owner={owner}
                  setOwner={setOwner}
                  ownerId={selectedOwnerId}
                  setOwnerId={setSelectedOwnerId}
                  apartment={apartment}
                  setApartment={setApartment}
                  setIsSharer={setIsSharer}
                />
              }
              {apartment?.Owners?.length > 0 ?
                <div className={styles.addParticipant}>
                  {!apartment?.RequestId &&
                    <Button
                      icon={plus}
                      large
                      type='primary'
                      text='Добавить участника'
                      onClick={() => {
                        setOpenParticipant(!openParticipant);
                        setSelectedOwnerId(null);
                        setOwner(null);
                      }}
                    />
                  }
                </div>
                :
                null
              }
              <div className={cn({ [styles.acts]: !isLoading })}>
                <p className={styles.title}>
                  {isLoading
                    ?
                    <Skeleton width='380px' height='19.5px'/>
                    :
                    <span>
                      Загрузка подписанных актов приема-передачи
                    </span>
                  }
                </p>
                <Check
                  label="Загрузите технический акт приема-передачи"
                  onClick={() => setCertificateTechnical(!certificateTechnical)}
                  selected={certificateTechnical}
                  skeleton={isLoading}
                  disabled={apartment?.RequestId}
                />
                {certificateTechnical && (
                  <div className={styles.documents}>
                    {apartment?.TechActAcceptanceFileName ? (
                      <SmallCard
                        avatar={pdf}
                        title={apartment?.TechActAcceptanceFileName}
                        onRemove={() => setApartment({
                          ...apartment,
                          TechActAcceptanceFileName: null,
                          TechActAcceptanceFileId: null
                        })}
                        onClick={() => downloadStorageFile(apartment?.TechActAcceptanceFileId, apartment?.TechActAcceptanceFileName)}
                      />
                    ) : (
                      <FileInput
                        accept={[mimeTypes.Pdf]}
                        onChange={(file) => {
                          const formData = new FormData();
                          formData.append('File', file);

                          uploadFile(formData).then(id => {
                            setApartment({
                              ...apartment,
                              TechActAcceptanceFileId: id,
                              TechActAcceptanceFileName: file.name,
                            });
                          })
                        }}
                      />
                    )}
                  </div>
                )}
                <Check
                  label="Акт приема-передачи помещения подписан"
                  onClick={() => setCertificate(!certificate)}
                  selected={certificate}
                  skeleton={isLoading}
                  disabled={apartment?.RequestId}
                />
                {certificate && (
                  <div>
                    <div className={styles.documents}>
                      <label className={styles.label}>
                        Загрузите подписанный сторонами и отсканированный акт
                        приема-передачи
                      </label>
                      {apartment?.ActFileName ? (
                        <SmallCard
                          avatar={pdf}
                          title={apartment?.ActFileName}
                          onRemove={!apartment?.RequestId ? () => {
                            setApartment({
                              ...apartment,
                              ActFileName: null,
                              ActFileId: null,
                              ActSigFileName: null,
                              ActSigFileId: null
                            });
                            setIsSignature(false)
                          } : null}
                          onClick={() => downloadStorageFile(apartment?.ActFileId, apartment?.ActFileName)}
                        />
                      ) : (
                        <FileInput
                          accept={[mimeTypes.Pdf]}
                          onChange={(file) => {
                            const formData = new FormData();
                            formData.append('File', file);

                            uploadFile(formData).then(id => {
                              setApartment({
                                ...apartment,
                                ActFileId: id,
                                ActFileName: file.name,
                              });
                            })
                          }}
                        />
                      )}
                    </div>
                    {apartment?.ActFileName && !apartment?.ActSigFileName && !apartment?.RequestId &&
                      <div className={styles.sigButtons}>
                        <Button isDisabled onClick={() => setIsSignature(!isSignature)} text='Загрузить подпись'/>
                        <span>или</span>
                        <Button isDisabled onClick={() => {
                          setIsSignature(false);
                          setOpenSignatureModal(true)
                        }} text='Подписать'/>
                      </div>
                    }
                    {apartment?.ActFileName && isSignature && (
                      <div className={styles.documents}>
                        {apartment?.ActSigFileName ? (
                          <SmallCard
                            avatar={pdf}
                            title={apartment?.ActSigFileName}
                            onRemove={() => {
                              setApartment({
                                ...apartment,
                                ActSigFileName: null,
                                ActSigFileId: null
                              });
                              setIsSignature(false)
                            }}
                            onClick={() => downloadStorageFile(apartment?.ActSigFileId, apartment?.ActSigFileName)}
                          />
                        ) : (
                          <FileInput
                            label='Загрузите подпись (sig-файл) к акту приема-передачи'
                            accept={[mimeTypes.Sig]}
                            onChange={(file) => {
                              const formData = new FormData();
                              formData.append('File', file);

                              uploadFile(formData).then(id => {
                                setApartment({
                                  ...apartment,
                                  ActSigFileId: id,
                                  ActSigFileName: file.name,
                                });
                              })
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          }
          <Signature
            isOpen={openSignatureModal}
            onClose={setOpenSignatureModal}
            fileName={apartment?.ActFileName}
            onChange={changeSignature}
            fileId={apartment?.ActFileId}
          />
        </div>
        </>
      )}
    </Modal>
  );
}
